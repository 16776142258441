<template>
  <div :class="$style.home">
    <div :class="$style.top">
      <div :class="$style.head">
        <img v-if="data.state_bar" :src="oss + '/manage/media/assets/statusbar_b.png'" alt="" />
        <img v-else :src="oss + '/manage/media/assets/statusbar_w.png'" alt="" />
      </div>
      <img :class="$style.back" :src="data.cover.cover" />
      <div :class="$style.title">文化</div>
      <div :class="$style.input">
        <div :class="$style.cbox">
          <i class="ri-search-2-line" />
          搜索文化点
        </div>
        <img :class="$style.inputMap" :src="oss + '/front/app/venue/map.png'" />
      </div>
    </div>
    <div :class="$style.banner">
      <img v-if="data.swiper.length" :src="data.swiper[0].data.cover" />
      <div v-if="data.swiper.length > 1" :class="$style.docMode">
        <span v-for="(item, index) in data.swiper" :key="index" :class="$style.doc" />
      </div>
    </div>

    <div :class="$style.box">
      <div v-for="(item, index) in data.group" :key="index" :class="$style.item">
        <img :src="item.icon" />
        <p :class="$style.name" class="ellipsis">{{ item.name }}</p>
      </div>
    </div>

    <p :class="$style.poctitle">
      附近场馆
      <span :class="$style.more">
        更多
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div>
      <div v-for="(item, index) in list" :key="index" :class="$style.poc">
        <div :class="$style.img">
          <div :class="$style.state" />
        </div>
        <div :class="$style.text">
          <div :class="$style.one" />
          <div :class="$style.two" />
          <div :class="$style.three" />
          <div :class="$style.four" />
          <div :class="$style.five" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { editorService } from '@/service'

// const service = new editorService()

export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [{ id: 1 }, { id: 2 }],
    }
  },
}
</script>

<style lang="less" module>
.home {
  .top {
    position: relative;
    width: 100%;
    height: 200px;

    .head {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 44px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .back {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .title {
      position: absolute;
      top: 44px;
      z-index: 1;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
    }

    .input {
      position: absolute;
      display: flex;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 200px;
      background-image: linear-gradient(to bottom, transparent, #fff);

      .cbox {
        position: relative;
        width: 304px;
        height: 36px;
        margin: 136px 10px 0 20px;
        padding: 8px 50px;
        color: #5c5c5c;
        background-color: #fff;
        border-radius: 100px;

        i {
          position: absolute;
          top: 4px;
          left: 18px;
          color: #5c5c5c;
          font-size: 20px;
        }
      }

      .inputMap {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        margin-top: 136px;
      }
    }
  }

  .banner {
    width: 350px;
    height: 120px;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  .box {
    width: 350px;
    margin: 0 auto 0;

    .item {
      width: 40px;
      text-align: center;
      border-radius: 6px;
      display: inline-block;
      margin-right: 37px;

      img {
        width: 40px;
        height: 40px;
        margin: 20px auto 0;
        object-fit: cover;
      }

      .name {
        margin-top: 10px;
        margin-bottom: 0;
        color: #5c5c5c;
        font-size: 12px;
      }
    }

    .item:nth-child(5n) {
      margin-right: 0;
    }
  }

  .poctitle {
    margin: 20px 20px 14px 20px;
    margin-bottom: 28px;
    color: #000;
    font-size: 18px;

    .more {
      float: right;
      margin-top: 8px;
      font-size: 12px;
      color: #5c5c5c;
    }
  }

  .doc-mode {
    position: absolute;
    bottom: 0%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);

    .doc {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin-right: 5px;
      line-height: 20px;
      background-color: #fff;
      border-radius: 5px;
    }

    .doc:first-child {
      width: 12px;
      background: @primary-color;
    }
  }

  .poc {
    width: 348px;
    margin: 0 20px;
    height: 140px;
    padding: 14px;
    background: #fff;
    margin-bottom: 24px;
    display: flex;
    border-radius: 6px;

    .img {
      width: 110px;
      height: 140px;
      position: relative;
      top: -28px;
      background: #eee;
      border-radius: 6px;

      .state {
        width: 40px;
        height: 18px;
        background: #fff;
        border-radius: 6px 0px 6px 0px;
      }
    }

    .text {
      flex: 1;
      padding-left: 14px;

      .one {
        width: 110px;
        height: 25px;
        background: #eee;
        margin-bottom: 10px;
      }

      .two {
        width: 198px;
        height: 12px;
        background: #eee;
        margin-bottom: 6px;
      }

      .three {
        width: 80px;
        height: 12px;
        background: #eee;
        margin-bottom: 10px;
      }

      .four {
        display: inline-block;
        width: 50px;
        height: 14px;
        background: #eee;
      }

      .five {
        display: inline-block;
        width: 50px;
        height: 14px;
        background: #eee;
        margin-left: 10px;
      }
    }
  }
}
</style>
