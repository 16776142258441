<template>
  <div>
    <bj-modal
      :class="$style.group"
      :visible="visible"
      :width="800"
      title="选择场馆分组"
      :body-style="{ height: '500px', 'max-height': '500px', position: 'relative', 'overflow-y': 'auto' }"
      @cancel="cancel"
    >
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="id">
        <template #choose="item">
          <div :class="$style.choose">
            <a-checkbox
              v-if="multiple"
              :checked="selectedRowKeys.indexOf(item.id) !== -1"
              @change="onChange(item.id, item)"
            />
            <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
          </div>
        </template>
        <template #cover="item">
          <img :class="$style.cover" :src="item.icon" />
        </template>
      </a-table>
      <template #footer>
        <a-row>
          <a-col class="text-left" span="12">
            <span :class="$style.select">{{ selectedRowKeys.length }}</span
            >个场馆分组已被选择
          </a-col>
          <a-col class="text-right" span="12">
            <BjButton @click="cancel()">取消</BjButton>
            <BjButton type="primary" @click="getChoose()">确定</BjButton>
          </a-col>
        </a-row>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import { appVenueGroupService } from '@/service'

const service = new appVenueGroupService()

export default {
  name: 'Home',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      selectedRowKeys: [],
      selectData: [],
      loading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        { title: '分组名称', dataIndex: 'name', width: 200 },
        {
          title: '分组图标',
          width: 100,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '包含场馆数', dataIndex: 'venue_total' },
        { title: '添加时间', dataIndex: 'created_at' },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.select && this.select.length) {
          this.select.map(({ id }) => {
            this.selectedRowKeys.push(id)
          })
        } else {
          this.selectedRowKeys = []
        }
        this.getList()
      } else {
        this.init()
      }
    },
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const { data } = await service.list({
          no_page: 1,
          no_default: 1,
        })
        this.data = data
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    getChoose() {
      if (this.selectedRowKeys.length) {
        let tmp = []
        this.selectedRowKeys.map(id => {
          for (let index = 0; index < this.selectData.concat(this.select).length; index++) {
            if (id === this.selectData.concat(this.select)[index].id) {
              tmp.push(this.selectData.concat(this.select)[index])
              break
            }
          }
        })
        this.$emit('change', tmp)
      }
      this.$emit('update:visible', false)
    },
    cancel() {
      this.$emit('update:visible', false)
    },
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
        this.selectData = []
        this.selectData.push(item)
      }
    },
    init() {
      this.selectedRowKeys = []
    },
  },
}
</script>

<style lang="less" module>
.group {
  .cover {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }

  .select {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    background: @primary-color;
    border-radius: 10px;
  }

  .foot {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
}
</style>
